// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Pagination, Autoplay,} from "swiper";

interface propsSlideContent {
  imgURL: string;
  text: any[];
}

const SlideClient: React.FC<propsSlideContent> = ({ imgURL,text }) => {
  return (
    <>
      <div className="slide-client__wrapper">
        <img src={imgURL} alt="cliente" className="slide-client__img" loading="lazy"/>

        <div className="slide-client__description">
          <h5 className="label-project">PROYECTO</h5>
          <Swiper
            className="mySwiper2"
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
          >
            {text.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  {" "}
                  <span className="slide-client__text">{item.description}</span>
                  <span className="slide-client__text">{item.period}</span>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SlideClient;
