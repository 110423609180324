import { information } from "./information";
import "./styles.css";

enum colors {
  dark = "var(--blue-dark-color)",
  light = "#e6e6e6",
}

const Services: React.FC = () => {
  return (
    <section className="service-section" id="servicios">
      <div className="wrapper-services-section">
        <h2 className="title-service">Servicios</h2>
        <div className="grid-services">
          {information.map((item, index) => {
            return (
              <div className={`service-item`} key={index}>
                <div
                  className="image-service-wrapper"
                  style={{
                    backgroundColor: item.isDark ? colors.dark : colors.light,
                  }}
                >
                  <img
                    src={item.urlImage}
                    alt="img service"
                    className="image-service"
                  />
                </div>
                <div
                  className={`content-item text-wrapper ${
                    item.isDark ? "dark-color" : "light-color"
                  }`}
                >
                  <h3
                    className="title-item"
                    style={{ color: item.isDark ? "#fff" : colors.dark }}
                  >
                    {item.title}
                  </h3>
                  <div className="animation_text">
                    <span
                      className="text-item"
                      style={{ color: item.isDark ? "#fff" : colors.dark }}
                    >
                      {item.description}
                      {item?.listTexts.map((text:string, index:number) => {
                      return (
                        <ul className="list-texts" key={index}>
                          <li>{text}</li>
                        </ul>
                      );
                    })}
                    </span>

                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
