import server from "../api/server"


export const sendEmail = async (data: any) => {
    server.post(`/contact/fitnance`,JSON.stringify(data),{
        headers: {
            'Content-Type': 'application/json',
        },
    }, )
        .then((res: any) => res.data)
        .catch((err: any) => console.error(err))
}


