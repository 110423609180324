import React from "react";
import { NavItem, NavLink } from "reactstrap";
import "./styles.css";

interface NavbarItemProps {
  itemTitle: string;
  itemLoc: string;
  toggle: Function;
}

const NavbarItem: React.FC<NavbarItemProps> = ({
  itemTitle = "",
  itemLoc = "",
  toggle,
}) => {
  const isMobile = () => {
    if (window.innerWidth < 992) {
      toggle();
    }
  };
  return (
    <NavItem className="nav-item" onClick={isMobile}>
      <NavLink href={itemLoc} >{itemTitle}</NavLink>
    </NavItem>
  );
};

export default NavbarItem;
