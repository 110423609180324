import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

interface modalWSDProps {
    isShow: boolean;
    toggle: () => void;
}

const ModalWSD: React.FC<modalWSDProps> = ({ isShow, toggle }) => {
  return (
    <Modal toggle={toggle} isOpen={isShow}>
      <ModalHeader toggle={toggle}>Correo enviado!</ModalHeader>
      <ModalBody>Gracias por contactarnos, recibirás una respuesta a la brevedad.</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Ok
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default ModalWSD;
