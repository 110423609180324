export const information = [
    {
        url: "/assets/IMAGEN2.jpg",
        isList: false,
        listTexts: [
            {
                title: "¿Quienes somos?",
                text: " Empresa mexicana fundada en 2017, en F-IT-NANCE CONSULTORES estamos especializados en finanzas y tecnologías de la información, con énfasis en el desarrollo e implementación de software financiero para el sector público, así como en servicios de marketing digital."
            }
        ]
    },
    {
        url: "/assets/IMAGEN3.jpg",
        isList: false,
        listTexts: [
            {
                title: "Misión",
                text: "Coadyuvar con las entidades gubernamentales y el sector privado para llevarlas a la vanguardia tecnológica, así como mejorar la eficiencia de sus procesos financieros y administrativos; tomando en cuenta la legislación aplicable, la normatividad y las mejores prácticas financieras. Asegurar, mediante el desarrollo de herramientas tecnológicas y la asesoría de nuestros consultores expertos, la atención de todos los requerimientos específicos de cada uno de nuestros clientes."
            },
            {
                title: "Visión",
                text: "Ser una empresa de reconocido prestigio nacional en el desarrollo e implementación se software administrativo y financiero para entidades gubernamentales e iniciativa privada."
            }
        ]
    },
    {
        url: "/assets/IMAGEN4.jpg",
        isList: true,
        listTexts: [
            {
                title: "Valores",
                text: [
                    "Honestidad",
                    "Integridad",
                    "Compromiso",
                    "Responsabilidad",
                    "Servicio",
                    "Respeto",
                    "Trabajo en equipo",
                ]
            },
        ]
    }
]