import Home from "./Views/Home";
import "./App.css";

import "./theme/variables.css";

const FitnanceApp = () => {
  return (
    <div className="App">
      <Home />
    </div>
  );
};

const App = () => {
  return (
      <FitnanceApp />
  );
};

export default App;
