// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import SlideCard from "./SlideCard";
import { information } from "./information";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";

const About: React.FC = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + "</span>";
    },
  };

  return (
    <>
      <div className="container-about" id="nosotros">
        <Swiper
          pagination={pagination}
          className="mySwiper swiper-about"
          modules={[Pagination]}
        >
          {information.map((item, index) => {
            return (
              <SwiperSlide key={index} className="swiper-slide-about">
                <SlideCard
                  listTexts={item.listTexts}
                  url={item.url}
                  isList={item.isList}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};
export default About;
