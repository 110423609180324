import { Spinner } from 'reactstrap';
import "./Loading.css"

function Loading() {
  return (
    <div className="spinner-view">
      <div className="container-spinner">
          <Spinner color="light"/>
          <span className="text-spinner">Loading...</span>
      </div>
    </div>
  );
}

export default Loading;
