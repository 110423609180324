// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { information } from "./information";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Navigation, Pagination } from "swiper";
import SlideClient from "./SlideClient";

const Clients: React.FC = () => {
  return (
    <>
      <section className="clients" id="referencias">
        <h2 className="title-section-clients">Nuestros Clientes</h2>
        <div className="wrapper-slider-client">
          <Swiper
            slidesPerView={3}
            spaceBetween={15}
            slidesPerGroup={3}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
                slidesPerGroup: 1,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 15,
                slidesPerGroup: 3,
              },
            }}
            loop={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper swiper-client"
          >
            {information.map((item, index) => {
              return (
                <SwiperSlide className="swiper-slide-client" key={index + 30}>
                  <SlideClient imgURL={item.imgURL} text={item.text} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Clients;
