import "./styles.css"

const textLocation = [
    "F-IT-nance Consultores S. de R. L. de C.V Trafalgar 2792, Colinas del Rey",
    "C.P: 45130, Zapopan, Jalisco"
]

const socialMedia = [
    {
        alt: "facebook icon",
        imageURL: "/assets/facebook-footer.png",
        link: "#",
    },
    {
        alt: "whatsapp icon",
        imageURL: "/assets/whatsapp-footer.png",
        link: "#",
    },
    {
        alt: "instagram icon",
        imageURL: "/assets/instagram-footer.png",
        link: "#",
    }
]

const Footer: React.FC = () => {
  return (
    <>
      <footer>
        <div className="footer__wrapper">
          <div className="wrapper-img-footer">
            <img src={"/assets/logo.png"} alt="logo" className="logo-footer" />
          </div>
          <div className="wrapper-location">
            {textLocation.map((text, index) => (
                <span key={index}>{text}</span>
            ))}
          </div>
          <div className="wrapper-social">
            {socialMedia.map((social, index) => (
                <a key={index} href={social.link} className="link-social">
                    <img src={social.imageURL} alt={social.alt} className="social-icon" />
                </a>
            ))}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
