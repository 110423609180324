import React from "react";
import "./styles.css";

interface SlideCardProps {
  listTexts: Array<any>;
  url: string;
  isList: boolean;
}

const SlideCard: React.FC<SlideCardProps> = ({ listTexts, url, isList }) => {
  return (
    <>
      <div className="card-section-about">
        <div
          className="container-text-about"
          style={{
            padding: `${listTexts.length > 1 ? "1em 10%" : "1em 14.5%"}`,
          }}
        >
          <div className="wrapper_text_about">
            {listTexts.map((text, index) => {
              return (
                <div className="item-text" key={index}>
                  <h2 className="title-about">{text.title}</h2>
                  {isList ? (
                    <>
                      <div className="list-about">
                        {text.text.map((item:any, index:number) => {
                          return (
                            <span className="item-list-about" key={index}>
                              &#10003; {item}
                            </span>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <span className="text-about">{text.text}</span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="container-img-about">
          <img src={url} alt={"img card"} className="img-card-about" loading="lazy" />
        </div>
      </div>
    </>
  );
};

export default SlideCard;
