import Button from "../../components/Button";
import FloatingButtons from "../../components/FloatingButtons";
import "./styles.css";

interface heroSectionProps {
  refContact?: React.RefObject<HTMLDivElement>;
}

const HeroSection: React.FC<heroSectionProps> = ({ refContact }) => {

  return (
    <>
      <section className="hero-section">
        <img
          src="/assets/bg_hero/IMAGEN1.jpg"
          alt="bg hero section"
          className="bg-hero-section"
          loading="lazy"
        />
        <div className="container-text-hero-section">
          <div className="wrapper_text_hero_section">
            <div className="content-img">
              <h1 className="title-hero-section">BIENVENIDOS</h1>
              <div className="subtitle-hero-section">
                <span>F-IT-NANCE CONSULTORES es una empresa mexicana especializada en las finanzas y en el desarrollo tecnológico ideal para tu negocio.</span>
              </div>
              <Button className="btn-white" onClick={() => {}} href="#contacto">Contacto</Button>
            </div>
          </div>
        </div>
        <FloatingButtons />

      </section>
    </>
  );
};

export default HeroSection;
