import './styles.css'
const HR: React.FC = () => {
  return (
    <>
      <div className="line-section">
        {/* <span className='line'></span> */}
      </div>
    </>
  );
};

export default HR;
