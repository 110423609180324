import "./styles.css";

interface ButtonProps {
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  type?: "submit" | "button";
  href?: string | undefined;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, className, type="button", href=null }) => {
  if (href) {
    return (
      <a href={href} className={`btn-action-link ${className}`}>
        {children}
      </a>
    );
  }
  return (
    <button className={`btn-action ${className}`} onClick={onClick} type={type}>
      {children}
    </button>
  );
};
export default Button;
