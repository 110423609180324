import "./styles.css"
const FloatingButtons: React.FC = () => {
  return (
    <div className="floating-buttons">
      <a className="floating-buttons__button" href="#blank">
        <img src="/assets/facebook.png" alt="icon facebook" className="img-floating" />
      </a>
      <div style={{width: "100%", height: "1px", backgroundColor: "#fff"}}></div>
      <a className="floating-buttons__button" href="#blank">
        <img src="/assets/whatsapp.png" alt="icon whatsapp"  className="img-floating" />
      </a>
    </div>
  );
};

export default FloatingButtons;
