export const information = [
    {
        title: "Aplicaciones",
        description: "Contamos con el personal experto para ofrecer los servicios de implementación, actualización y soporte técnico.",
        isDark: false,
        urlImage: "assets/aplicaciones.svg",
        listTexts: []
    },
    {
        title: "Arrendamiento",
        description: "Ofrecemos la opción de arrendamiento de las aplicaciones, incluyendo el servicio de hospedaje de las aplicaciones arrendadas.",
        isDark: true,
        urlImage: "assets/venta.png",
        listTexts: []
    },
    {
        title: "Desarrollos a la medida",
        description: "Contamos con personal experto en todas las etapas del desarrollo de software, por lo que podemos construir la aplicación más apropiada a sus necesidades.",
        isDark: true,
        urlImage: "assets/code.svg",
        listTexts: []
    },
    {
        title: "Servicios de consultoría",
        description: "Considerando la amplia experiencia y alto grado de conocimiento de nuestros consultores técnicos y funcionales en todos los temas relacionados con finanzas y procesos administrativos gubernamentales, ofrecemos también servicios de consultoría para apoyar a nuestros clientes en diversos aspectos, tales como:",
        isDark: false,
        urlImage: "assets/actualizacion.png",
        listTexts: [
            "Evaluación del software que utilizan en el ente público",
            "Reingeniería de procesos financieros",
            "Implementación de nuevos procesos administrativos"
        ]
    }, 
    {
        title: "Marketing Digital",
        description: " Ofrecemos servicios de marketing digital para posicionar a nuestros clientes en herramientas como Google, You Tube y en diversas redes sociales, de tal forma que hacemos llegar de manera efectiva los mensajes que nos encomiendan.",
        isDark: false,
        urlImage: "assets/marketing.svg",
        listTexts: []
    },
    {
        title: "Monitoreo de contenido",
        description: "Monitoreo y análisis del desempeño en redes oficiales, sitios web y medios digitales de su organización.",
        isDark: true,
        urlImage: "assets/monitor.svg",
        listTexts: []
    }, 
]