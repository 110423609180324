import "./styles.css";
interface propsSlideContent {
  text: string;
  description: string;
}

const SlideContent: React.FC<propsSlideContent> = ({ text, description }) => {
  return (
    <>
      <div className="wrapper_text_slide_content">
        <h5>{text}</h5>
        <span>{description}</span>
      </div>
    </>
  );
};

export default SlideContent;
