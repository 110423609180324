import HR from "../../components/HR";
import Navbar from "../../components/Navbar";
import About from "../../ViewComponents/About";
import Clients from "../../ViewComponents/Clients";
import ContactUs from "../../ViewComponents/ContactUs";
import Footer from "../../ViewComponents/Footer";
import HeroSection from "../../ViewComponents/HeroSection";
import Services from "../../ViewComponents/Services";
import Solution from "../../ViewComponents/Solution";

import "./styles.css";

const Home: React.FC = () => {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main className="home__wrapper">
        <HeroSection />
        <HR></HR>
        <About />
        <HR></HR>
        <Services />
        <HR></HR>
        <Solution />
        <HR></HR>
        <Clients />
        <HR></HR>
        <ContactUs />
        <HR></HR>
        <Footer />
      </main>
    </>
  );
};

export default Home;
