import { useState } from "react";
import Button from "../../components/Button";
import Loading from "../../components/Loading/Loading";
import ModalWSD from "../../components/ModalWSD";
import { sendEmail } from "../../services/contact";
import "./styles.css";
const ContactUs: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");

  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data: any = {
        Name: name,
        Email: email,
        PhoneNumber: phone,
        Message: comment,
      };
      await sendEmail(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
    // setName("");
    // setEmail("");
    // setPhone("");
    // setComment("");
    toggle();
  };

  const formData = [
    {
      name: "name",
      label: "Nombre:",
      type: "text",
      value: name,
      onChange: ({ target: { value } }: any) => setName(value),
      isInput: true,
      iconInput: "/assets/icons/user.svg",
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: email,
      onChange: ({ target: { value } }: any) => setEmail(value),
      isInput: true,
      iconInput: "/assets/icons/mail.svg",
    },
    {
      name: "phone",
      label: "Teléfono:",
      type: "number",
      value: phone,
      onChange: ({ target: { value } }: any) => setPhone(value),
      isInput: true,
      iconInput: "/assets/icons/phone.svg",
    },
    {
      name: "comment",
      label: "Comentario:",
      type: "text",
      value: comment,
      onChange: ({ target: { value } }: any) => setComment(value),
      isInput: false,
    },
  ];

  const toggle = () => setIsShow(!isShow);

  return (
    <>
      {isLoading && <Loading />}
      <ModalWSD isShow={isShow} toggle={toggle} />
      <section className="contact-us" id="contacto">
        <div className="wrapper_form">
          <h2 className="title-contact">Contáctanos</h2>
          <form className="form-contact" onSubmit={handleSubmit}>
            {formData.map((item, index) => {
              return (
                <div
                  className={`${
                    !item.isInput
                      ? "input-group-text-area"
                      : "input-group-contact"
                  }`}
                  key={index}
                >
                  {item.isInput ? (
                    <input
                      type={item.type}
                      id={item.name}
                      placeholder={item.label}
                      className="input-control-contact"
                      value={item.value}
                      onChange={item.onChange}
                    />
                  ) : (
                    <textarea
                      id={item.name}
                      placeholder={item.label}
                      className="text-area-control input-control-contact"
                      style={{ paddingTop: "10px" }}
                      value={item.value}
                      onChange={item.onChange}
                    
                    ></textarea>
                  )}
                </div>
              );
            })}
            <div className="btn-wrapper-contact">
              <Button className="btn-red" onClick={() => {}} type="submit">
                Enviar{" "}
              </Button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
