export const information = [
    {
        text: "Sistema de Armonización Contable (SAC).",
        description: "Con esta aplicación implementamos un Modelo de Administración Financiera y Armonización Contable que permita a los entes públicos optimizar su administración integral con una visión basada en la gestión para resultados en toda su operación y cumpliendo con toda la legislación y normatividad aplicable.",
    },
    {
        text: "Sistema de Administración de la deuda pública de los entes públicos.",
        description: "Mediante este sistema el ente público puede administrar de manera integral la deuda pública, conforme lo establecen la Ley General de Contabilidad Gubernamental y la Ley de Disciplina Financiera para los Estados y Municipios del País."
    },
    {
        text: "Sistema para administrador de la obra pública y la inversión de entes públicos.",
        description: "Aplicación que permite administrar y transparentar el proceso de gestión y ejecución de la obra pública. Permite la visualización de la obra pública que se desarrolla por el ente público, incluyendo: montos de inversión, estimaciones, duración de la obra, proceso de asignación de la obra, sujetándose a los presupuestos autorizados."
    },
    {
        text: "Sistema Laudos y de Juicios.",
        description: "Permite gestionar mediante un seguimiento basado de semáforos, fechas de términos, montos y escalamiento de mensajes, el flujo y los demás aspectos importantes que deben administrarse en cualquier área jurídica de la tesorería de un estado, municipio u organismo público, con la finalidad de tener control sobre los casos y no perderlos por falta de atención a los mismos."
    }
]