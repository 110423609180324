export const information = [
    {
        imgURL: "/assets/zapopan1.png",
        text: [
            {
                description: "Implementación del Sistema de Deuda Pública",
                period: "Septiembre 2019 a Marzo 2020",
            },
        ]
    },
    {
        imgURL: "/assets/guadalajara1.png",
        text: [
            {
                description: "Implementación del GRP denominado Sistema de Armonización Contable (SAC)",
                period: "Septiembre 2022 a Diciembre 2022",
            }
        ]
    },
    {
        imgURL: "/assets/jalisco.png",
        text: [
            {
                description: "Implementación del Sistema de Laudos",
                period: "Septiembre a Diciembre 2017",
            },
            {
                description: "Póliza de Soporte del Sistema de Laudos",
                period: "Enero a Noviembre 2018",
            }
        ]
    },
    {
        imgURL: "/assets/udg.png",
        text: [
            {
                description: "Servicios de pauta digital, Google Youtube",
                period: "2021",
            },
            {
                description: "Campaña del rector General 2021",
                period: "",
            }
        ]
    },
    {
        imgURL: "/assets/aqua.png",
        text: [
            {
                description: "Póliza de Soporte del Sistema de Armonización de Contable SAC",
                period: "Enero a Diciembre 2020",
            },
            {
                description: "Póliza de Soporte del Sistema de Armonización de Contable SAC",
                period: "Enero a Diciembre 2021",
            },
            {
                description: "Póliza de Soporte al Sistema de Armonización Contable SAC",
                period: "Enero a Diciembre 2022",
            }
        ]
    },
    {
        imgURL: "/assets/fiscalia.png",
        text: [
            {
                description: "Póliza de Soporte del Sistema de Armonización de Contable SAC",
                period: "Enero a Diciembre 2020",
            },
            {
                description: "Póliza de Soporte del Sistema de Armonización de Contable SAC",
                period: "Enero a Diciembre 2021",
            },
            {
                description: "Póliza de Soporte al Sistema de Armonización Contable SAC",
                period: "Enero a Diciembre 2022",
            },
            {
                description: "Monitoreo y análisis del desempeño en redes oficiales, sitios web y medios digitales del Gobierno del Estado de Sinaloa (2022).",
                period: "2022"
            }
        ]
    },

]