// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { information } from "./information";
import SlideContent from "./SlideContent";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Navigation } from "swiper";

const Solution: React.FC = () => {
  return (
    <>
      <section className="solution-section">
        <h2 className="title-solutions">Soluciones</h2>
        <div className="wrapper-swiper-solution">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper swiper-solution"
          >
            {information.map((item, index) => {
              return (
                <SwiperSlide className="swiper-slide-solution" key={index}>
                  <SlideContent
                    text={item.text}
                    description={item.description}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Solution;
